import { DataRoomDocument, DataRoomDocumentType } from './data-room.interface';
import { Tag, TagCountry } from './mandatory-docs.interface';

export enum DataStatus {
  Valid = 'VALID',
  Invalid = 'INVALID',
  Shadow = 'SHADOW',
  Unmodified = 'UNMODIFIED'
}

export enum ActualSituationType {
  UscUStax = 'USC_USTAX',
  BornUSNotUSC = 'BORN_US_NOT_USC',
  NotUSC = 'NOT_USC'
}

export enum ProfileType {
  Individual = 'INDIVIDUAL',
  Entity = 'ENTITY',
  Any = 'ANY',
  Joint = 'JOINT'
}

export enum IndividualType {
  Member = 'MEMBER',
  Main = 'MAIN_PRINCIPAL'
}

export interface GpPolicy {
  gpPolicy: {
    id: string;
    policyContext: string;
  };
  status: string;
}

export enum IPayMethodType {
  BANK = 'BANK',
  IRA = 'IRA'
}

export interface Individual {
  dataCreateTime: number;
  dataIsDeleted: boolean;
  dataStatus: DataStatus;
  dataUpdateTime: number;
  /** 🆔 */
  id: string;
  /** 关联的投资主体Id */
  referencePrincipalId: string;
  /** 姓名 */
  firstName: string;
  lastName: string;
  /** 曾用名 */
  previousName?: string;
  /** 📮 */
  email: string;
  /** 号码归属地编码 */
  phoneNationalityCode: string;
  /** 📱 */
  phoneNumber: string;
  /** 国籍 */
  nationalityCode: string;
  townOfBirth: string;
  /** 第二国籍 */
  anotherNationalityCode?: string;
  /** 出生国家 */
  countryCodeOfBirth: string;
  /** 居住国家 */
  countryCodeOfResidence: string;
  /** 出生日期 */
  dateOfBirth: string;
  birthday: string;
  /** 职业 */
  occupation?: string;
  occupationComment?: string;
  /** 美国身份类型 */
  actualSituationType: ActualSituationType;
  jobPositionTitle?: string;
  taxJurisdiction: string;
  individualType: IndividualType;
}

export enum EntityStructureType {
  SingleInvestors = 'SINGLE_INVESTORS',
  PooledVehicle = 'POOLED_VEHICLE',
  ProfessionallyManagedVehicle = 'PROFESSIONALLY_MANAGED_VEHICLE'
}

export enum CustodianAccountStatus {
  Closed = 'Closed',
  Open = 'Open',
  Pending = 'Pending'
}

export enum CustodianAccountType {
  ROTH_IRA = 'ROTH_IRA',
  TRADITIONAL_IRA = 'TRADITIONAL_IRA'
}

export enum LabelCustodianAccountType {
  ROTH_IRA = 'Roth IRA',
  TRADITIONAL_IRA = 'Traditional IRA'
}

export interface Entity {
  dataCreateTime: number;
  dataIsDeleted: boolean;
  dataStatus: DataStatus;
  dataUpdateTime: number;
  id: string;
  legalName: string;
  contactEmail: string;
  phoneCode: string;
  phoneNumber: string;
  countryCodeIncorporation: string;
  dateOfIncorporation: Date;
  incorporationDate: string;
  natureOfBusiness: string;
  entityType?: string;
  entityTypeComment?: string;
  actualSituationType: ActualSituationType;
  nationalRegistrationNumber: string;
}

export interface Custodian {
  dataCreateTime?: number;
  dataIsDeleted?: boolean;
  dataStatus?: string;
  dataUpdateTime?: number;
  id: string;
  mtAccountNumberLast4: string;
  mtTaxCode: string;
  mtTaxType: string;
  referenceInvestorProfileMetaIndividualId: string;
  firstName?: string;
  lastName?: string;
  accountType?: CustodianAccountType;
  accountStatus?: CustodianAccountStatus;
}

export interface Address {
  id: string;
  referenceInvestorProfileMetaIndividualId: Individual['id'];
  referenceInvestorProfileMetaEntityId: Entity['id'];
  addressCountryCode: string;
  addressState: string;
  addressTown: string;
  addressLineOne: string;
  addressLineTwo: string;
  zipCode: string;
  referenceProofAddressFileId: number;
  proofAddressFileUploadDatetime: Date;
  referenceProofAddressFileEntity: {
    documents: DataRoomDocument;
  };
  tag?: Tag;
  country?: TagCountry;
}

export enum IdentityDocumentType {
  Passport = 'PASSPORT'
}

export interface Identity {
  dataCreateTime: number;
  dataIsDeleted: boolean;
  dataStatus: DataStatus;
  dataUpdateTime: number;
  id: string;
  referenceInvestorProfileMetaIndividualId: Individual['id'];
  referenceInvestorProfileMetaEntityId: Entity['id'];
  documentType: IdentityDocumentType;
  identityNumber: string;
  expiredDate: Date;
  expirationDate: string;
  referenceProofFileId: number;
  referenceProofCertificateFileEntity: {
    documents: DataRoomDocument;
  };
  tag?: Tag;
  country?: TagCountry;
}

export interface Bank {
  dataCreateTime: number;
  dataIsDeleted: boolean;
  dataStatus: DataStatus;
  dataUpdateTime: number;
  id: string;
  referenceInvestorProfileMetaId: Profile['id'];
  referenceInvestorProfileMetaIndividualId: Individual['id'];
  bankAccountName: string;
  bankName: string;
  bankAccountNumber: string;
  bankSwiftCode: string;
  bankAddress: string;
  correspondentBankName: string;
  correspondentBankBranchAccountNumber: string;
  correspondentBankSwiftCode: string;
  proofFileId: string;
  referenceProofBankFileEntity: {
    documents: DataRoomDocument;
  };
  tag?: Tag;
  country?: TagCountry;
  referenceProofBankFileId?: string;
}

export interface EntityIdentity {
  id: string;
  referenceProofCertificateFileEntity: {
    documents: any;
  };
}

export interface Profile {
  id: string;
  profileType: ProfileType;
  payMethod: IPayMethodType;
  investorProfileMetaIndividual?: Individual;
  investorProfileMetaEntity?: Entity;
  investorProfileMetaInfoCert?: Identity;
  investorProfileMetaInfoBank?: Bank;
  investorProfileMetaInfoAddress?: Address;
  investorProfileMtCustodianData?: Custodian;
  actualSituationType?: ActualSituationType;
  investorProfileMetaInfoMemberList?: EntityMember[];
  investorProfileMetaInfoCertificateList: EntityIdentity[];
  structureType?: EntityStructureType;
  referenceProofCertificateFileEntity: {
    documents: DataRoomDocument;
  };
  productSubscriptionProfile?: productSubscription;
  mandatoryDocuments?: MandatoryDocumentInfo[];
  valueDisabledRadio?: boolean;
}

export interface productSubscription {
  actualSituationType: string;
  dataCreateTime: string;
  dataIsDeleted: boolean;
  dataStatus: string;
  dataUpdateTime: string;
  id: string;
  profileType: string;
  referencePrincipalId: string;
  referenceProfileInfoAddressId: string;
  referenceProfileInfoBankId: string;
  referenceProfileMetaId: string;
  structureType: string;
}

export enum EntityMemberRole {
  AuthorisedSignatories = 'EXTERNAL',
  ControllingPerson = 'CONTROLLING_PERSON',
  Director = 'DIRECTOR'
}

export interface EntityMember {
  id: string;
  memberRole: EntityMemberRole;
  isSigningAuthority: boolean;
  referenceInvestorMetaIndividualEntity: Individual;
  legalInfo: { name: string; type: unknown }[];
  referenceInfoAddressEntity: Address;
  referenceInvestorMetaEntityId: string;
  referenceInfoCertificateEntity: Identity;
  referenceInvestorMetaIndividualId: string;
  percentageOwnership: number;
  isUsPerson: boolean;
}

export enum GroupType {
  IDENTITY = 'IDENTITY'
}
export interface TenantAttribute {
  attributeName: string;
  attributeValue: string;
  description: string;
  groupType: GroupType;
  isCheckAttribute: number;
  tenantAttributeId: string;
  tenantId: string;
}

export enum documentTypeEnum {
  PASSPORT = 'PASSPORT',
  DRIVING_LICENCE = 'DRIVING_LICENCE',
  NATIONAL_ID_CARD = 'NATIONAL_ID_CARD',
  MILITARY_ID_CARD = 'MILITARY_ID_CARD'
}

export const DOCUMENT_TYPE = {
  [documentTypeEnum.PASSPORT]: 'Passport',
  [documentTypeEnum.DRIVING_LICENCE]: 'Driving licence',
  [documentTypeEnum.NATIONAL_ID_CARD]: 'National ID card',
  [documentTypeEnum.MILITARY_ID_CARD]: 'Military ID card'
};

export enum policyType {
  ENTITY_CERTIFIED_SUPPORT = 'ENTITY_CERTIFIED_SUPPORT',
  INVESTOR_BANK_SUPPORT = 'INVESTOR_BANK_SUPPORT',
  PROOF_OF_IDENTITY = 'PROOF_OF_IDENTITY',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  OWNER_DIRECTOR_TRUSTEE_BENEFICIARY = 'OWNER_DIRECTOR_TRUSTEE_BENEFICIARY',
  ROLE_IN_THE_ENTITY = 'ROLE_IN_THE_ENTITY',
  ADDITIONAL_DOCUMENTS = 'ADDITIONAL_DOCUMENTS',
  TAX_JURISDICTION = 'TAX_JURISDICTION',
  INFORMATION_ADDRESS = 'INFORMATION_ADDRESS',
  INFORMATION_IDENTITY = 'INFORMATION_IDENTITY',
  INFORMATION_BANK = 'INFORMATION_BANK',
  TAX_REPORTING = 'TAX_REPORTING',
  INCORPORATION_ID = 'INCORPORATION_ID',
  ENTITY_TYPE = 'ENTITY_TYPE',
  PERCENTAGE_OWNERSHIP = 'PERCENTAGE_OWNERSHIP',
  LEGAL_NAME_OF_ENTITY = 'LEGAL_NAME_OF_ENTITY',
  TYPE_OF_CONTROLLING_PERSON = 'TYPE_OF_CONTROLLING_PERSON',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  POSITION_OR_TITLE = 'POSITION_OR_TITLE'
}

export interface policy {
  dataCreateTime: string;
  dataIsDeleted: boolean;
  dataStatus: string;
  dataUpdateTime: string;
  id: string;
  policyContext: string;
  policyType: policyType;
  tenantId: string;
}

export interface AdditionalDocument {
  category?: string;
  dataCreateTime?: number;
  dataUpdateTime?: number;
  documentDescription?: string;
  documentName?: string;
  fileUrl?: string;
  id: string;
  investorDocumentId?: string;
  normalUsage?: string;
  productId?: string;
  productMdId?: string;
  profileInvestorMappingId?: string;
  fileType?: DataRoomDocumentType;
  fileName?: string;
  owner?: { name: string; id: string; type: string };
  tag?: Tag;
  country?: TagCountry;
  downloadUrl?: string;
}

export const PROFILE_TYPE = {
  [ProfileType.Individual]: 'Individual',
  [ProfileType.Entity]: 'Entity',
  [ProfileType.Any]: 'Any',
  [ProfileType.Joint]: 'Joint Account'
};

export enum AddMandatoryDocumentResponse {
  success = 'SUCCESS',
  profileIdNotExist = 'PROFILE_ID_NOT_EXIST',
  fileNotExist = 'FILE_NOT_EXIST',
  fail = 'FAIL'
}

export const ADD_MANDATORY_DOC_RES_MSG = {
  [AddMandatoryDocumentResponse.success]: 'Success',
  [AddMandatoryDocumentResponse.profileIdNotExist]: 'Profile id is not exist',
  [AddMandatoryDocumentResponse.fileNotExist]: 'File is not exist',
  [AddMandatoryDocumentResponse.fail]: 'Add document failed'
};

export interface MandatoryDocumentInfo {
  documentDescription: string;
  documentName: string;
  files: any;
  fundMandatoryDocId: string;
  isMandatory: boolean;
  mandatoryDocumentKey: string;
  multiple: boolean;
  owner: any;
}

export interface ProfileInvestorDocument {
  id: string;
  documentName: string;
  documentDescription?: string;
  fileName: string;
  fileSize: number;
  fileType: DataRoomDocumentType;
  fileUrl: string;
  downloadUrl: string;
  owner?: {
    id: string;
    name: string;
    type: string;
  };
  tag?: Tag;
  country?: TagCountry;
}

export enum ProfileSignatoryType {
  personally = 'PERSONALLY',
  onBehalf = 'ON_BEHALF'
}
