import { FundamentalStatusEnum, FundamentalTypeEnum } from '.';
import { Project } from './scenario.interface';

export interface StatusDetail {
  type: string;
  value?: string;
  key?: string;
  date?: string;
}
export interface ProcessItem {
  dataCreateTime: string;
  dataIsDeleted: boolean;
  dataStatus: string;
  id: string;
  progress: string;
  referenceSubscriptionId: string;
  status: StepStatus;
  tenantId: string;
  type: string;
  detail?: StatusDetail[];
  isOpen?: boolean;
  resend?: boolean;
}
export interface ProductSubscription {
  committedAmount: number;
  distributionAmount: number;
  investedAmount: number;
  navAmount?: number;
  productSubscriptionId: string;
  subscriptionStepStatus: SubscriptionStepStatus;
  timeStartedDatetime: number;
  undrawnAmount: number;
  processes: ProcessItem[];
  shareClassCurrencyCode: string;
  isRedeem: number;
  redeemed: number;
  subscriptionShares?: number;
}

export interface InvestmentFacade {
  committedAmount: number;
  distributionAmount: number;
  investedAmount: number;
  navAmount: number;
  productId: string;
  productLogo: string;
  productName: string;
  productSubscriptionList: ProductSubscription[];
  undrawnAmount: number;
  subscriptionCount: number;
  sourceTenantName: string;
  sourceTenantLogo: string;
  sourceTenantSiteUrl: string;
  fundamentalStatus: string;
  productType: FundamentalTypeEnum;
}

export enum SubscriptionStepStatus {
  INVESTING = 'INVESTING',
  ASSESS_RISK = 'ASSESS_RISK',
  SUBMITTED = 'SUBMITTED',
  REVIEW = 'REVIEW',
  SIGN = 'SIGN',
  RESERVED = 'RESERVED', // 预定
  SIGNING = 'SIGNING', // 签署中
  COMMITTED = 'COMMITTED', // 提交
  ASSESSING_RISK = 'ASSESSING_RISK',
  OFFLINE_SIGNING = 'OFFLINE_SIGNING',
  REVIEWING = 'REVIEWING',
  APPROVED = 'APPROVED', // 已批准
  INVESTED = 'INVESTED', // 已投资
  FULLY_INVESTED = 'FULLY_INVESTED', // 全部已投资
  REJECTED = 'REJECTED', // 拒绝
  EXPIRED = 'EXPIRED', // 拒绝
  CLOSE = 'CLOSE', // 拒绝

  AWAITING_ADDITIONAL_DOCUMENTS = 'AWAITING_ADDITIONAL_DOCUMENTS',
  ADDITIONAL_DOCUMENTS_UPLOADED = 'ADDITIONAL_DOCUMENTS_UPLOADED',
  CAPITAL_CALL_OUTSTANDING = 'CAPITAL_CALL_OUTSTANDING',
  RECEIPT_CONFIRMED = 'RECEIPT_CONFIRMED',
  SIGNED = 'SIGNED',
  FUND_RECEIVED = 'FUND_RECEIVED',
  PARTIALLY_REDEEMED = 'PARTIALLY_REDEEMED',
  FULLY_REDEEMED = 'FULLY_REDEEMED'
}

export const SUBSCRIPTION_STEP_STATUS_LABEL = {
  [SubscriptionStepStatus.INVESTING]: 'Investing',
  [SubscriptionStepStatus.ASSESS_RISK]: 'Assess risk',
  [SubscriptionStepStatus.SUBMITTED]: 'Submitted',
  [SubscriptionStepStatus.REVIEW]: 'Review',
  [SubscriptionStepStatus.SIGN]: 'Sign',
  [SubscriptionStepStatus.RESERVED]: 'Reserved',
  [SubscriptionStepStatus.SIGNING]: 'Signing',
  [SubscriptionStepStatus.COMMITTED]: 'Committed',
  [SubscriptionStepStatus.AWAITING_ADDITIONAL_DOCUMENTS]: 'Awaiting additional documents',
  [SubscriptionStepStatus.ADDITIONAL_DOCUMENTS_UPLOADED]: 'Additional documents uploaded',
  [SubscriptionStepStatus.ASSESSING_RISK]: 'Assessing risk',
  [SubscriptionStepStatus.OFFLINE_SIGNING]: 'Offline signing',
  [SubscriptionStepStatus.REVIEWING]: 'Reviewing',
  [SubscriptionStepStatus.APPROVED]: 'Approved',
  [SubscriptionStepStatus.CAPITAL_CALL_OUTSTANDING]: 'Capital call outstanding',
  [SubscriptionStepStatus.FUND_RECEIVED]: 'Funds received',
  [SubscriptionStepStatus.INVESTED]: 'Invested',
  [SubscriptionStepStatus.RECEIPT_CONFIRMED]: 'Receipt confirmed',
  [SubscriptionStepStatus.FULLY_INVESTED]: 'Fully Invested',
  [SubscriptionStepStatus.PARTIALLY_REDEEMED]: 'Partially Redeemed',
  [SubscriptionStepStatus.FULLY_REDEEMED]: 'Fully Redeemed',
  [SubscriptionStepStatus.REJECTED]: 'Rejected',
  [SubscriptionStepStatus.CLOSE]: 'Close',
  [SubscriptionStepStatus.EXPIRED]: 'Expired',
  [SubscriptionStepStatus.SIGNED]: 'Signed'
};

export enum StepStatus {
  INVESTING = 'INVESTING',
  ASSESS_RISK = 'ASSESS_RISK',
  SUBMITTED = 'SUBMITTED',
  SIGN = 'SIGN',
  SIGNING = 'SIGNING',
  SIGNED = 'SIGNED',
  COMMITTED = 'COMMITTED',
  ASSESSING_RISK = 'ASSESSING_RISK',
  RISK_ASSESSED = 'RISK_ASSESSED',
  REVIEWING = 'REVIEWING',
  APPROVED = 'APPROVED',
  INVESTED = 'INVESTED',
  FULLY_INVESTED = 'FULLY_INVESTED',
  REJECTED = 'REJECTED',
  REVIEW = 'REVIEW',
  OFFLINE_SIGNING = 'OFFLINE_SIGNING',
  CONFIRM_SUBSCRIPTION = 'CONFIRM_SUBSCRIPTION',
  AWAITING_ADDITIONAL_DOCUMENTS = 'AWAITING_ADDITIONAL_DOCUMENTS',
  ADDITIONAL_DOCUMENTS_UPLOADED = 'ADDITIONAL_DOCUMENTS_UPLOADED',
  CAPITAL_CALL_OUTSTANDING = 'CAPITAL_CALL_OUTSTANDING',
  RECEIPT_CONFIRMED = 'RECEIPT_CONFIRMED',
  FUND_RECEIVED = 'FUND_RECEIVED',
  PARTIALLY_REDEEMED = 'PARTIALLY_REDEEMED',
  FULLY_REDEEMED = 'FULLY_REDEEMED',
  REDEMPTION_PENDING = 'REDEMPTION_PENDING'
}

export const STEP_STATUS_LABEL = {
  [StepStatus.INVESTING]: 'Investing',
  [StepStatus.ASSESS_RISK]: 'Assess risk',
  [StepStatus.SIGN]: 'Sign',
  [StepStatus.SUBMITTED]: 'Submitted',
  [StepStatus.SIGNING]: 'Signing',
  [StepStatus.CONFIRM_SUBSCRIPTION]: 'Confirm subscription',
  [StepStatus.SIGNED]: 'Signed',
  [StepStatus.AWAITING_ADDITIONAL_DOCUMENTS]: 'Awaiting additional documents',
  [StepStatus.ADDITIONAL_DOCUMENTS_UPLOADED]: 'Additional documents uploaded',
  [StepStatus.COMMITTED]: 'Committed',
  [StepStatus.ASSESSING_RISK]: 'Assessing risk',
  [StepStatus.RISK_ASSESSED]: 'Risk assessed',
  [StepStatus.REVIEW]: 'Review',
  [StepStatus.OFFLINE_SIGNING]: 'Offline signing',
  [StepStatus.REVIEWING]: 'Reviewing',
  [StepStatus.APPROVED]: 'Approved',
  [StepStatus.CAPITAL_CALL_OUTSTANDING]: 'Capital call outstanding',
  [StepStatus.INVESTED]: 'Invested',
  [StepStatus.RECEIPT_CONFIRMED]: 'Receipt confirmed',
  [StepStatus.FULLY_INVESTED]: 'Fully Invested',
  [StepStatus.REJECTED]: 'Rejected',
  [StepStatus.FUND_RECEIVED]: 'Funds received',
  [StepStatus.PARTIALLY_REDEEMED]: 'Partially Redeemed',
  [StepStatus.FULLY_REDEEMED]: 'Fully Redeemed',
  [StepStatus.REDEMPTION_PENDING]: 'Redeemed Pending'
};
export enum FundTypeEnum {
  OPEN_ENDED_FUNDS = 'OPEN_ENDED',
  CLOSE_ENDED_FUNDS = 'CLOSE_ENDED'
}

export interface NavLineGraphPolyline {
  name: string;
  navLineGraphDimensionVOS: {
    dateTime: number;
    nav: number;
  }[];
  currency?: string;
}

export interface InvestmentProductSubscription extends ProductSubscription {
  shareClassId: number;
  shareClassName: string;
  subscriptionPricePerShare: 0;
  project?: Project;
  createByType: 'GP' | 'LP';
  lateSign?: boolean;
  targetConfirmSubscriptionPricePerShare?: number;
  redmeemed?: number;
}

export interface InvestmentDetail extends InvestmentFacade {
  productStatus: FundamentalStatusEnum;
  productSubscriptionList: InvestmentProductSubscription[];
  productType: FundamentalTypeEnum;
}

export interface Transaction {
  amount: number;
  capitalCallDetailId: string;
  createTimes: number;
  currency: string;
  dataCreateTime: Date;
  dataIsDeleted: boolean;
  dataStatus: string;
  dataUpdateTime: Date;
  endTimes: number;
  id: string;
  principalId: string;
  principalNumber: string;
  productId: string;
  status: string;
  subscriptionCode: string;
  subscriptionId: string;
  tenantId: string;
  transactionCode: string;
  type: string;
  tokenStatus?: string;
}

export interface InvestorCalls {
  proportion: number;
  subscriptionFee: number;
  dataIsDeleted: boolean;
  callAmount: number;
  dueDate: number;
  receiveAttachment: {
    name: string;
    documentToken: string;
    type: string;
    url: string;
  };
  totalCall: number;
  baseCurrency: string;
  id: string;
  dataUpdateTime: string;
  capitalCallId: string;
  receiptDate: number;
  dataStatus: string;
  receivedAmount: number;
  dataCreateTime: number;
  incomingInvestorFlag: string;
  commitmentAmount: number;
  tenantId: string;
  receiveRemark: string;
  subscriptionId: string;
  status: string;
}

export enum TransactionType {
  TRANSFER_FUNDS = 'TRANSFER_FUNDS', // CALL 款
  SUBSCRIPTION_FEE = 'SUBSCRIPTION_FEE', // 订阅费、认购费
  DISTRIBUTION = 'DISTRIBUTION', // 分红,
  DISTRIBUTED = 'DISTRIBUTED',
  REDEMPTION = 'REDEMPTION'
}

export enum TransactionStatus {
  PENDING = 'PENDING', // 未确认
  RECEIVED = 'RECEIVED', // 确认收款
  DISTRIBUTED = 'DISTRIBUTED', // 分红
  PAID = 'PAID'
}

interface BaseNAV {
  createTime?: Date;
  date: Date;
  id: string;
  productId: string;
  shareClassId: string;
}

export interface CloseEndedNAV extends BaseNAV {
  distributionsToPaidInCapital: number;
  dividend?: number;
  fundNavToPaidInCapital: number;
  irr: number;
  nav: number;
  paidInCapitalToCapitalCommitments: number;
  totalRetainedEarningsB: number;
  totalRetainedEarningsC: number;
  totalValueToPaidInCapital: number;
  [key: string]: any;
}

export interface OpenEndedNAV extends BaseNAV {
  annualisedDividendYield: number;
  dividendPerShare: number;
  indexedReturnFromInception: number;
  monthlyReturn: number;
  navPerShare: number;
  returnFromInception: number;
}

export interface ComfirmReceiptNAV {
  dateTime: number;
  nav: number;
}

export interface ShareClassAnalysis {
  closeEndedNavList?: CloseEndedNAV[];
  openEndedNavList?: OpenEndedNAV[];
  comfirmReceiptNavList?: ComfirmReceiptNAV[];
  shareClassId: string;
  shareClassName: string;
  baseCurrency?: string;
}

export enum ProductSubscriptionEnum {
  subscriptionIsNotInvested = 'subscriptionIsNotInvested'
}

export interface ProductReport {
  id: string;
  statementName: string;
  statementType: string;
  dateOfReport?: string;
  downloadUrl?: string;
  statementUrl?: string;
  reportDescription?: string;
  productId?: string; // use for frontend
  disabledDownload?: boolean; // use for frontend
}

export interface ProductReportFolder {
  id: string;
  folderName: string;
  child?: ProductReportFolder[];
}

export type RedemptionStatus = 'PENDING' | 'COMPLETED' | 'CANCELLED';

export type Redemptions = {
  amount: number;
  createdBy: string;
  dataCreateTime: Date;
  id: string;
  sharesNumber: number;
  status: RedemptionStatus;
  susbcriptionId: string;
  dataStatus: string;
  pricePerShare: number;
  redemptionDate: Date;
  redemptionType: string;
  redemptionMethod: string;
};
