import { ReCaptchaPayload } from '@stream/models';

import { Statistics } from './dashboard.model';
import { TenantStatus } from './tenant.model';

export enum GpLoginStatusEnum {
  Success = 'success',
  Failed = 'failed',
  ReCaptchaVerifyFailed = 'reCaptchaVerifyFailed',
  MFAIsRequired = 'mfaIsRequired',
  NO_PERMISSION = 'NO_PERMISSION',
  MFA_IS_NOT_ENABLE = 'mfaIsNotEnable',
  ACCOUNT_LOCKOUT_PROMPT = 'ACCOUNT_LOCKOUT_PROMPT',
  LOGIN_FREQUENCY_LIMIT = 'LOGIN_FREQUENCY_LIMIT',
  ACCOUNT_LOCKOUT = 'ACCOUNT_LOCKOUT'
}

export enum InviteCodeStatus {
  Success = 'valid',
  Expired = 'expired',
  HasValidated = 'hasvalidated',
  NotExist = 'notexist'
}

export interface WorkspaceToken {
  accessToken: string;
  refreshToken: string;
  gpAccountEmail?: string;
}

/** 登录信息 */
export interface SignInDTO {
  /** 登录成功状态 */
  gpLoginStatusEnum: GpLoginStatusEnum;
  passwordAttemptsLimit?: number;

  /** 当前登录账号的tokens */
  gpLoginInfo: DomainToken[];
  tokenInfo: WorkspaceToken;
  message?: string;
  validateEmail?: string;
  ticket?: string;
}

export interface RefreshTokenRes {
  gpLoginStatusEnum: GpLoginStatusEnum;
  message?: string;
  tokenResponse: {
    accessToken: string;
    refreshToken: string;
  };
}
/** 保存用户信息 */
export interface SaveAccountInfo {
  /** 保存成功成功状态 */
  gpAccountSaveStatusEnum: GpLoginStatusEnum;
  /** 当前登录账号的tokens */
  gpLoginInfo: DomainToken[];
  tokenInfo: WorkspaceToken;
  message?: string;
}
export interface SignUpInfo {
  /** 组织名 */
  companyName: string;
  /** 子域前缀 */
  domainPrefix: string;
  /** 注册邮箱 */
  email: string;
  /** 完善注册的临时token */
  temporaryToken: string;
  /** 用户名 */
  userName: string;
}
export interface DomainToken {
  /**邮箱 */
  gpAccountEmail: string;
  gpAccountId: string;
  /**租户ID */
  tenantId: number;
  /** 用户名 */
  userName: string;
  /** 组织名 */
  companyName: string;
  /** 子域前缀ID */
  domainPrefixId: number;
  /** 子域前缀 */
  prefixValue: string;
  /** ？ */
  fullDomainValue: string;
  redirectUrl: string;
  accessToken: string;
  refreshToken: string;
  /** 本地存储头像颜色 */
  color: string;
  /** 域 logo */
  companyLogoUrl: string;
  /** 临时 token，Safari 下跨域使用*/
  tokenId: string;
  /** 账号状态 */
  gpAccountStatus?: 'locked' | 'active' | 'inactive' | 'notexist';
  gpRoles: string[];
  gpAccountSource: string;
  /** 子域消息条数 */
  taskCount: number;
  /** 子域角色列表 */
  roles: string[];
  bizOps: {
    name: string;
    email: string;
    gpAccountId: string;
  }[];
  statistics?: Statistics;
  identityTypes: string[];
  /** 自定义域名 */
  customDomain: string;
  /** Tenant 是否状态，是否被禁用 */
  status: TenantStatus;
}

export interface DomainTokenGroup {
  /** 账号 */
  email: string;
  /** 账号下的token */
  domains: DomainToken[];
}

export interface SignInPayload extends ReCaptchaPayload {
  email: string;
  password: string;
  tenantId?: string;
  validatorLinkCode?: string;
}
